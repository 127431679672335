import * as _react3 from "react";
var _react2 = "default" in _react3 ? _react3.default : _react3;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePrevious = void 0;
var _react = _react2;
// Used to "remember" previous value of each individual digit
var usePrevious = function usePrevious(value) {
  var ref = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    ref.current = value;
  }, [value]);
  return ref.current;
};
exports.usePrevious = usePrevious;
export default exports;